import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import CopyRight from "../components/copyright"
import Bio from '../components/Bio'
import SocialList from "../components/socialList"
import { rhythm } from '../utils/typography'

class AboutPage extends React.Component {
  render() {
    // get props
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')

    return (
      <Layout location={this.props.location}>

        <Helmet>
          <meta name="language" content="en" />
          <title>About - {siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>

        <header>
          <h1 style={{ color: 'dodgerblue' }}>About</h1>
          <p>Learn more about macyuppie.com and the author Ben Partch.</p>
        </header>

        <hr />

        <main>
          <article>
            <h2 style={{ color: 'dodgerblue' }}>Hello and welcome to MacYuppie!</h2>
            <p>When I got my first mac (iMac mid 2011) back in 2011; I bought this domain with the intention of helping other Windows users make the switch to OS X. Well, I'm a web deveeloper by trade and got offered a job, took it and never got around to creating the site. :P</p>
            <h2 style={{ color: 'dodgerblue' }}>Why Now?</h2>
            <p>The primary purpose of this website is to teach myself GatsbyJS on which the site is powered; and simce I left my job recently, it will give me something to do.</p>
            <SocialList />
          </article>
        </main>

        <hr style={{ marginBottom: rhythm(1), }}/>

       <Bio />
       <CopyRight />
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`